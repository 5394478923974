import {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";

import { debounceFunction } from "App-config";

import "components/order/style/OrderDetail.css";

const useInput = ({
  initialValue,
  pIndex,
  shopTotalAmt,
  myPoint,
  usePoint,
  initElement,
  pointChangeHandler,
  userInfo,
}) => {
  const remainPoint = myPoint - usePoint;
  const [value, setValue] = useState(initialValue);

  const submitPoint = useCallback(
    debounceFunction((val) => pointChangeHandler(pIndex, val), 400),
    []
  );

  const changeHandler = (val) => {
    setValue(val);
    submitPoint(val);
  };

  const onChange = (event, c2_idx) => {
    // 기프티콘 포인트 50%만 사용가능
    let gifticonProductPrice = Math.round(shopTotalAmt / 2);
    const targetVal = event.target.value;
    if (!isNaN(targetVal) && parseInt(targetVal) > 0) {
      const val =
        c2_idx == 20
          ? targetVal.replace(/(^0+)/, "") * 1 > 4000
            ? 4000
            : targetVal.replace(/(^0+)/, "") * 1
          : c2_idx == 21
          ? gifticonProductPrice >= targetVal.replace(/(^0+)/, "") * 1
            ? targetVal.replace(/(^0+)/, "") * 1
            : gifticonProductPrice
          : targetVal.replace(/(^0+)/, "") * 1;

      if (c2_idx != 21 && remainPoint > shopTotalAmt) {
        if (shopTotalAmt - val < 0) {
          changeHandler(shopTotalAmt);
        } else {
          changeHandler(val);
        }
      } else {
        if (remainPoint - val < 0) {
          changeHandler(remainPoint);
        } else {
          changeHandler(val);
        }
      }
    } else {
      changeHandler(0);
    }
    initElement.current.checked = false;
  };

  const initialVlaueHandler = (event, c2_idx) => {
    // 기프티콘 포인트 50%만 사용가능
    let gifticonProductPrice = Math.round(shopTotalAmt / 2);

    const changeHandler = (val) => {
      setValue(val);
      pointChangeHandler(pIndex, val);
    };
    // 영화관람권은 최대 4000원 사용
    if (event.target.checked) {
      if (c2_idx != 21 && remainPoint > shopTotalAmt) {
        // if (remainPoint > shopTotalAmt) {
        let newTotalAmt = c2_idx == 20 ? 4000 : shopTotalAmt;
        changeHandler(newTotalAmt);
      } else {
        let newTotalAmt =
          c2_idx == 20
            ? remainPoint > 4000
              ? 4000
              : remainPoint
            : c2_idx == 21
            ? gifticonProductPrice >= remainPoint
              ? remainPoint
              : gifticonProductPrice
            : remainPoint;
        changeHandler(newTotalAmt);
      }
    } else {
      changeHandler(0);
    }
  };

  return { value, onChange, initialVlaueHandler };
};

const OrderUsePoint = forwardRef(
  (
    {
      payIndex,
      shopTotalAmt,
      myPoint,
      usePoint,
      pointChangeHandler,
      sIdx,
      c2Idx,
      userInfo,
    },
    ref
  ) => {
    const checkRef = useRef();
    const point = useInput({
      initialValue: 0,
      pIndex: payIndex,
      shopTotalAmt: shopTotalAmt,
      myPoint: myPoint,
      usePoint: usePoint,
      initElement: checkRef,
      pointChangeHandler: pointChangeHandler,
      userInfo: userInfo,
    });

    useImperativeHandle(ref, () => ({
      pointReset,
    }));

    const pointReset = () => {
      point.onChange({ target: { checked: false } });
    };

    return (
      <div className="wrap_frame">
        <div className="kiikey-point-use-wrap">
          <div className="kiikey-point-use">
            <span className="order-title">이쁨 포인트 사용</span>
            <div className="kiikey-have-point">
              <span>
                <p>보유 포인트</p>
                <p className="font-Bahnschrift">
                  {(myPoint - usePoint)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  <span className="font-weght-light4"> 원</span>
                </p>
              </span>
              {
                <span>
                  <input
                    type="checkbox"
                    id={"point-use" + payIndex}
                    name={"point-use" + payIndex}
                    ref={checkRef}
                    disabled={sIdx === 12526 ? false : true}
                    onClick={(e) => {
                      point.initialVlaueHandler(e, c2Idx);
                    }}
                  />
                  <label htmlFor={"point-use" + payIndex} />
                  전액사용
                </span>
              }
            </div>
            <div className="kiikey-point-used">
              <span>사용할 포인트</span>
              <input
                id="point-use-input"
                type="text"
                disabled={sIdx === 12526 ? false : true}
                placeholder="0"
                value={
                  point.value
                  /*                 .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") */
                }
                onChange={(e) => {
                  point.onChange(e, c2Idx);
                }}
              />
            </div>
            {c2Idx == 20 && (
              <span style={{ color: "red", fontSize: 12, fontWeight: "600" }}>
                <br />
                영화티켓 구매는 최대 4,000포인트 사용하실 수 있습니다.
              </span>
            )}
            {c2Idx == 21 && (
              <span style={{ color: "red", fontSize: 12, fontWeight: "600" }}>
                <br />
                기프티콘 포인트사용은 상품금액의 50%만 사용 가능합니다.
              </span>
            )}
          
          </div>
        </div>
      </div>
    );
  }
);

export default OrderUsePoint;
