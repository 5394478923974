/* global nicepaySubmit */
/* global nicepayClose */
/* global nicepayStart */
/* global goPay */

import { useMemo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  cancelOrder,
  delBasketConfirm,
  getMemberInfo,
  getPayBasket,
  paySuccess,
  submitPayment,
} from "service/OrderService";
import {
  checkPlatform,
  isKiikeyApp,
  serverErrorHandler,
  callOrder,
} from "App-config";

import PaymentHeader from "components/order/payment/PaymentHeader";
import OrderRequest from "components/order/payment/OrderRequest";
import OrderContents from "components/order/payment/OrderContents";
import OrderUsePoint from "components/order/payment/OrderUsePoint";
import OrderPayType from "components/order/payment/OrderPayType";
import FinalConfirm from "components/order/payment/FinalConfirm";

import pre from "src_assets/allow-button-left.png";

import "components/order/style/OrderDetail.css";
import stateUtilityInstance from "TestClass";

import { ReactComponent as LocalShippingIcon } from "src_assets/icon/local_shipping.svg";
import { ReactComponent as PinDropIcon } from "src_assets/icon/pin_drop.svg";
import { getDelHistoryList } from "service/AccountService";

const { goPay } = window;

const Payment = ({ isMobile, userInfo, setUserInfo }) => {
  const targetForm = useRef();
  const navigate = useNavigate();

  const paymentTypeRef = useRef(null);

  const [basketList, setBasketList] = useState([]);

  const [isPorc, setIsProc] = useState(false);

  const [myPoint, setMyPoint] = useState(0);
  const [usePoint, setUsePoint] = useState(0);

  const [usePointList, setUsePointList] = useState([]);
  const [delFeeList, setDelFeeList] = useState([]);

  const [isCreate, setIsCreate] = useState(false);
  const [odIdxList, setOdIdxList] = useState([]);

  const [formData, setFormData] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [mbAddr, setMbAddr] = useState(null);

  const totalAmt = useMemo(() => {
    let totalPrice = 0;
    basketList.forEach((basket) => {
      totalPrice += basket.totalPrice;
    });
    return totalPrice;
  }, [basketList, usePointList, delFeeList]);
  // 포장매장이 있을시 비활성화
  const isNotMeet = useMemo(() => {
    let _isNotMeet = false;
    if (basketList.length > 0) {
      for (let i = 0; i < basketList.length; i++) {
        const basket = basketList[i];
        if (basket.od_option != 2) {
          _isNotMeet = true;
          break;
        }
      }
    }
    return _isNotMeet;
  }, [basketList]);

  const totalDelFee = useMemo(() => {
    return delFeeList.reduce((prev, current) => prev + current, 0);
  }, [delFeeList]);

  useEffect(() => {
    // window.onpageshow = (e) => {
    //   console.log("page show : ", e);
    //   navigate("/order/payment");
    // };
    stateUtilityInstance.setMobileHeaderTitle("주문결제하기", true);

    window.nicepaySubmit = (e) => {
      const temp = {
        PayMethod: targetForm.current.PayMethod.value,
        GoodsName: targetForm.current.GoodsName.value,
        Amt: targetForm.current.Amt.value,
        MID: targetForm.current.MID.value,
        Moid: targetForm.current.Moid.value,
        BuyerName: targetForm.current.BuyerName.value,
        BuyerTel: targetForm.current.BuyerTel.value,
        EdiDate: targetForm.current.EdiDate.value,
        SignData: targetForm.current.SignData.value,
        ReturnURL: targetForm.current.ReturnURL.value,
        AuthResultCode: targetForm.current.AuthResultCode.value,
        AuthResultMsg: targetForm.current.AuthResultMsg.value,
        NextAppURL: targetForm.current.NextAppURL.value,
        TxTid: targetForm.current.TxTid.value,
        AuthToken: targetForm.current.AuthToken.value,
        PayMethod: targetForm.current.PayMethod.value,
        MID: targetForm.current.MID.value,
        Moid: targetForm.current.Moid.value,
        Amt: targetForm.current.Amt.value,
        ReqReserved: targetForm.current.ReqReserved.value,
        NetCancelURL: targetForm.current.NetCancelURL.value,
      };
      paySuccess(temp)
        .then((res) => {
          setTimeout(() => {
            try {
              document.getElementById("nice_layer").remove();
              document.getElementById("bg_layer").remove();
            } catch (error) {}
            setIsCreate(false);
            navigate("/order/payresult");
          }, 700);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    window.nicepayStart = () => {
      setTimeout(() => {
        if (checkPlatform() === "mobile" || isMobile) {
          //모바일 결제창 진입
          targetForm.current.action =
            "https://web.nicepay.co.kr/v3/v3Payment.jsp";

          targetForm.current.submit();
          setIsProc(false);
        } else {
          //PC 결제창 진입
          goPay(targetForm.current);
        }
      }, 800);
    };

    getMemberInfo()
      .then((res) => {
        setUserInfo(res);

        var split = res.mb_addr.split("/");

        var addrArray = [];
        addrArray.push(split[0]?.trim() || "");
        addrArray.push(split[1]?.trim() || "");
        addrArray.push(split[2]?.trim() || res.mb_name);
        addrArray.push(split[3]?.trim() || res.mb_id);
        addrArray.push(split[4]?.trim() || res.mb_phone);

        setMbAddr(addrArray);

        //setMbAddr(res.mb_addr);
        setMyPoint(res.mb_point * 1);
      })
      .catch((err) => {
        console.error("payment: err :: ", err);
        //에러처리필요
        serverErrorHandler(err);
      });
  }, []);

  useEffect(() => {
    const phoneNumRegex = /^(\d{3})(\d{3,4})(\d{4})$/;
    /* const [, phoneNum1, phoneNum2, phoneNum3] =
      phoneNumRegex.exec(userInfo.mb_phone) || [];
    setPhoneNum1(phoneNum1 || "");
    setPhoneNum2(phoneNum2 || "");
    setPhoneNum3(phoneNum3 || "");*/

    if (Object.keys(userInfo).length > 0)
      getPayBasket()
        .then((res) => {
          if (res.success) {
            if (res.data.rows.length < 1) {
              // alert("결제할 상품이 존재하지 않습니다.");
              navigate("/order/confirm");
            } else {
              setBasketList(res.data.rows);
              setUsePointList(
                res.data.rows.map(() => {
                  return 0;
                })
              );

              const totalDel = res.data.rows.map(
                (basket) => getDelFee(basket).delFee
              );
              setDelFeeList(totalDel);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          serverErrorHandler(err);
        });
  }, [userInfo]);

  useEffect(() => {
    if (odIdxList.length > 0) {
      const sp_pay_type = document.querySelector(
        'input[name="payment-type"]:checked'
      ).value;
      if (totalAmt + totalDelFee - usePoint > 0 && sp_pay_type > 10) {
        submitPayment({ odIdxList, isKiikeyApp })
          .then((res) => {
            if (res.success) {
              setFormData(res.data);
            }
          })
          .catch((err) => {
            console.error("err", err);
          });
        setIsCreate(true);
      } else {
        setIsProc(false);
        if (isKiikeyApp) {
          const is_multi = odIdxList.length > 1 ? "1" : "0";
          const m_od_idx = Math.min(...odIdxList);
          window.location.href =
            "/kiikeyApp/orderResult?od_idx=" +
            m_od_idx +
            "&is_multi=" +
            is_multi +
            "&odOption=" +
            basketList[0].od_option;
        } else {
          window.location.replace(
            "/order/payResult?odOption=" + basketList[0].od_option
          );
          // let returnUrl = window.location.protocol + "//" + window.location.host + "/order/payresult?odOption=" + basketList[0].od_option;
          // //window.location.href = window.location.window.location+"//"+ window.location.host + "/order/payresult?odOption=" + basketList[0].od_option;
          // navigate("/order/payresult?odOption=" + basketList[0].od_option, { replace: true});
        }
      }
    }
    window.nicepayClose = () => {
      alert("결제가 취소 되었습니다");
      setIsProc(false);
      setIsCreate(false);
    };

    if (!isMobile) {
      // 결제모듈이 떠있을때 뒤로가기시
      window.addEventListener("popstate", window.nicepayClose);
      return () => {
        window.removeEventListener("popstate", window.nicepayClose);
      };
    }
  }, [odIdxList]);

  useEffect(() => {
    if (odIdxList.length > 0 && isCreate) {
      window.nicepayStart();
    }
  }, [isCreate]);

  const getDelFee = (basket) => {
    const shopMinPrice = basket.s_min_price * 1;
    const currentTotalPrice = basket.totalPrice;

    // 매장의 최소금액 조건을 만족하지 못한다
    if (shopMinPrice - currentTotalPrice > 0) {
      console.log("최소금액이 1.");
      return { action: 1, delFee: 0 };
    }

    const currentDongIdx = userInfo.dong_idx;
    const locationSucc = basket.tip_location.filter(
      (location) => location.dong_idx === currentDongIdx
    );
    // 매장의 배송 가능 동과 유저의 동이 같은게 없다
    /*  if (locationSucc.length === 0) {
      console.log("배송가능한 지역이 아닙니다1.");
      return { action: 2, delFee: 0 };
    }*/

    // 배송비이 필요없는 주문
    console.log("배송비이 필요없는 주문11." + basket.od_option);
    if (basket.od_option != "2" && basket.od_option != "5") {
      console.log("배송비이 필요없는 주문.");
      return { action: 4, delFee: 0 };
    }

    //const tipLocationPrice = locationSucc[0].t_price * 1;
    let tipPrice = 0;
    basket.tip_price.forEach((tip) => {
      if (tip.t_std_price * 1 >= currentTotalPrice) {
        console.log(
          "배송비이 필요있는 주문13." +
            tip.t_std_price * 1 +
            "==" +
            currentTotalPrice
        );
        tipPrice = tip.t_price * 1;
      }
    });

    return { action: 3, delFee: tipPrice };
  };

  const nameChangeHandler = (e) => {};
  const telCheckHandler = (e, basket, pIndex) => {
    console.log("telCheckHandler");
  };
  const addrCheckHandler = (e, basket, pIndex) => {
    const { target } = e;
    if (target.checked) {
      document.getElementById("direct-checkbox" + pIndex).checked = false;

      if (!addrCheck(basket)) {
        target.checked = false;
      }
    }
  };
  const directCheckHandler = (e, basket, pIndex) => {
    const { target } = e;
    let newDelFeeList = [...delFeeList];
    let newUsePointList = [...usePointList];
    if (target.checked) {
      document.getElementById("address-checkbox" + pIndex).checked = false;
      if (!directCheck(basket)) {
        target.checked = false;
      }
      newDelFeeList = newDelFeeList.map(() => {
        return 0;
      });
      newUsePointList = newUsePointList.map(() => {
        return 0;
      });
      document.getElementById("delFee").innerText = "0원";
      // id 가 userPoint인 요소의 값을 0으로

      const pointuse = document.getElementById("point-use" + pIndex);

      if (pointuse.checked) {
        document.getElementById("point-use" + pIndex).click();
      }
      setDelFeeList(newDelFeeList);
      setUsePointList(newUsePointList);
      alert("아산, 서울사무실 중 원하는 수령지를 요청사항에 남겨 주세요.");
    } else {
      const { action, delFee } = getDelFee(basket);
      console.log(delFee);
      newDelFeeList = newDelFeeList.map(() => {
        return delFee;
      });
      newUsePointList = newUsePointList.map(() => {
        return 0;
      });
      //pointReset 함수를 호출해줘
      const pointuse = document.getElementById("point-use" + pIndex);
      if (pointuse.checked) {
        document.getElementById("point-use" + pIndex).click();
      }
      setDelFeeList(newDelFeeList);
      setUsePointList(newUsePointList);
      document.getElementById("delFee").innerText =
        delFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
      //document.getElementById("delFee").innerText=delFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"원";
    }
  };
  const addrCheck = (basket) => {
    const odOption = basket.od_option * 1;
    const { action } = getDelFee(basket);
    if (
      basket.c2_idx != 4 &&
      action === 2 &&
      (odOption === 2 || odOption === 5)
    ) {
      alert("배송가능한 지역이 아닙니다." + basket.c2_idx);
      return false;
    }
    if (action === 1) {
      const shopMinPrice = basket.s_min_price * 1;
      const currentTotalPrice = basket.totalPrice;
      alert(
        `매장의 주문 금액이 (${currentTotalPrice})\n매장의 최소 주문금액 (${shopMinPrice})보다 커야합니다.`
      );
      return false;
    }
    return true;
  };
  const telCheck = (basket) => {
    return true;
  };
  const directCheck = (basket) => {
    document.getElementById("delFee").innerText = "0원";

    return true;
  };

  const basketDeleteHandler = (targetIdx, isShop) => {
    let rqArg = {};
    if (isShop) {
      rqArg.sIdx = targetIdx;
    } else {
      rqArg.bmIdx = targetIdx;
    }
    delBasketConfirm(rqArg)
      .then((res) => {
        let targetSidx = 0;
        let afterLength = 0;

        let newBasketList = [...basketList];
        let newDelFeeList = [...delFeeList];
        let newUsePointList = [...usePointList];
        if (isShop) {
          for (let index = 0; index < newBasketList.length; index++) {
            const element = newBasketList[index];
            if (element.s_idx == targetIdx) {
              setUsePoint(0);

              newBasketList.splice(index, 1);
              newDelFeeList.splice(index, 1);
              newUsePointList = newUsePointList.map(() => {
                return 0;
              });

              targetSidx = element.s_idx;
              afterLength = newBasketList.length;
              break;
            }
          }
          if (afterLength < 1) {
            navigate(-1);
          }
        } else {
          for (let index = 0; index < newBasketList.length; index++) {
            const element = newBasketList[index];
            for (let j = 0; j < element.rows.length; j++) {
              const rows = element.rows[j];
              if (rows.bm_idx == targetIdx) {
                newBasketList[index].totalPrice =
                  newBasketList[index].totalPrice - rows.totalPrice;
                const { action, delFee } = getDelFee(newBasketList[index]);

                newDelFeeList[index] = delFee;
                newBasketList[index].rows.splice(j, 1);

                newUsePointList = newUsePointList.map(() => {
                  return 0;
                });

                targetSidx = element.s_idx;
                afterLength = newBasketList[index].rows.length;
                break;
              }
            }
          }

          if (afterLength < 1) {
            for (let index = 0; index < newBasketList.length; index++) {
              const element = newBasketList[index];
              if (element.s_idx == targetSidx) {
                setUsePoint(0);

                newBasketList.splice(index, 1);
                newDelFeeList.splice(index, 1);
                newUsePointList = newUsePointList.map(() => {
                  return 0;
                });

                break;
              }
            }
            if (newBasketList.length < 1) {
              navigate(-1);
            }
          }
        }

        setBasketList(newBasketList);
        setDelFeeList(newDelFeeList);
        setUsePointList(newUsePointList);
      })
      .catch((err) => {
        console.error(err);
        serverErrorHandler(err);
      });
  };
  const priceChangeHandler = (targetIdx, value) => {
    let targetSidx = 0;
    let afterLength = 0;
    let newBasketList = [...basketList];
    let newDelFeeList = [...delFeeList];
    let newUsePointList = [...usePointList];
    console.log(targetIdx + "priceChangeHandler" + value);

    for (let index = 0; index < newBasketList.length; index++) {
      const element = newBasketList[index];
      newBasketList[index].totalPrice = 0;
      for (let j = 0; j < newBasketList[index].rows.length; j++) {
        const rows = newBasketList[index].rows[j];
        console.log(
          newBasketList[index].rows[j].bm_idx +
            "priceChangeHandler" +
            targetIdx +
            "==" +
            newBasketList[index].rows[j].totalPrice
        );
        if (newBasketList[index].rows[j].bm_idx == targetIdx) {
          newBasketList[index].rows[j].totalPrice = parseInt(value);
        }
        newBasketList[index].totalPrice =
          newBasketList[index].totalPrice +
          newBasketList[index].rows[j].totalPrice;
        console.log(newBasketList[index].totalPrice);
        const { action, delFee } = getDelFee(newBasketList[index]);

        newDelFeeList[index] = delFee;
        // newBasketList[index].rows.splice(j, 1);

        newUsePointList = newUsePointList.map(() => {
          return 0;
        });

        targetSidx = newBasketList[index].s_idx;
        afterLength = newBasketList[index].rows.length;
        // break;
      }
    }
    if (afterLength < 1) {
      for (let index = 0; index < newBasketList.length; index++) {
        const element = newBasketList[index];
        if (element.s_idx == targetSidx) {
          setUsePoint(0);

          newBasketList.splice(index, 1);
          newDelFeeList.splice(index, 1);
          newUsePointList = newUsePointList.map(() => {
            return 0;
          });

          break;
        }
      }
      if (newBasketList.length < 1) {
        navigate(-1);
      }
    }

    setBasketList(newBasketList);
    setDelFeeList(newDelFeeList);
    setUsePointList(newUsePointList);
  };
  const pointChangeHandler = (pIndex, value) => {
    if (pIndex < 0) {
      pIndex = 0;
    }

    if (myPoint < value) {
      value = myPoint;
    }

    let newList = usePointList;
    newList[pIndex] = value;

    let newTotalPoint = newList.reduce(
      (prev, current) => prev * 1 + current * 1
    );

    setUsePointList(newList);
    setUsePoint(newTotalPoint);
  };

  return (
    <section className="delivery-history-frame">
      {/* <div className="title_area">
        <div className="delivery-title-pre">
          <img src={pre} alt="뒤로가기"></img>
        </div>
        <span className="delivery-title">주문 내역</span>
      </div> */}
      {basketList &&
        basketList.map((basket, index) => {
          return (
            <PaymentArticle
              totalAmt={totalAmt}
              basket={basket}
              pIndex={index}
              nameChangeHandler={nameChangeHandler}
              telCheckHandler={telCheckHandler}
              addrCheckHandler={addrCheckHandler}
              directCheckHandler={directCheckHandler}
              userInfo={userInfo}
              basketDeleteHandler={basketDeleteHandler}
              priceChangeHandler={priceChangeHandler}
              pointChangeHandler={pointChangeHandler}
              delFeeList={delFeeList}
              myPoint={myPoint}
              usePoint={usePoint}
              setModalOpen={setModalOpen}
              mbAddr={mbAddr}
            />
          );
        })}
      {/* <PickupTime /> */}
      {/* <NewOrderUsePoint
        totalAmt={totalAmt}
        myPoint={myPoint}
        usePoint={usePoint}
        pointChangeHandler={pointChangeHandler}
        userInfo={userInfo}
      /> */}
      <OrderPayType
        totalAmt={totalAmt}
        myPoint={myPoint}
        usePoint={usePoint}
        pointChangeHandler={pointChangeHandler}
        userInfo={userInfo}
        isNotMeet={isNotMeet}
        sIdx={basketList[0]?.s_idx}
      />

      <FinalConfirm
        sIdx={basketList[0]?.s_idx}
        basketList={basketList}
        totalAmt={totalAmt}
        myPoint={myPoint}
        usePoint={usePoint}
        isPorc={isPorc}
        setIsProc={setIsProc}
        setIsCreate={setIsCreate}
        setOdIdxList={setOdIdxList}
        totalDelFee={totalDelFee}
        delFeeList={delFeeList}
        addrCheck={addrCheck}
        userInfo={userInfo}
        mbAddr={mbAddr}
        paymentTypeRef={paymentTypeRef}
      />
      <div className="final-confirmation">
        <form
          name="payForm"
          action={formData.returnURL}
          method="post"
          acceptCharset="euc-kr"
          ref={targetForm}
        >
          <input type="hidden" name="PayMethod" value={formData.payMethod} />
          <input type="hidden" name="GoodsName" value={formData.goodsName} />
          <input type="hidden" name="Amt" value={formData.price} />
          <input type="hidden" name="MID" value={formData.MID} />
          <input type="hidden" name="Moid" value={formData.moid} />
          <input type="hidden" name="BuyerName" value={formData.buyerName} />
          <input type="hidden" name="BuyerEmail" value="" />
          <input type="hidden" name="BuyerTel" value={formData.buyerTel} />
          <input type="hidden" name="ReturnURL" value={formData.returnURL} />
          <input type="hidden" name="VbankExpDate" value="" />
          <input type="hidden" name="TransType" value="0" />
          <input type="hidden" name="CharSet" value="euc-kr" />
          <input type="hidden" name="GoodsCl" value="1" />
          <input
            type="hidden"
            name="ReqReserved"
            value={formData.reqReserved}
          />
          <input type="hidden" name="EdiDate" value={formData.ediDate} />
          <input type="hidden" name="SignData" value={formData.hashString} />
        </form>
      </div>

      {modalOpen && (
        <DelHistoryModal
          userInfo={userInfo}
          setModalOpen={setModalOpen}
          setMbAddr={setMbAddr}
        />
      )}
    </section>
  );
};

const PaymentArticle = ({
  totalAmt,
  basket,
  pIndex,
  userInfo,
  basketDeleteHandler,
  priceChangeHandler,
  pointChangeHandler,
  nameChangeHandler,
  telCheckHandler,
  addrCheckHandler,
  directCheckHandler,
  delFeeList,
  myPoint,
  usePoint,
  setModalOpen,
  mbAddr,
}) => {
  const childComponentRef = useRef();
  const navigate = useNavigate();

  console.log(userInfo);

  const getAddrString = () => {};
  const getName = () => {
    const split = mbAddr.split("/");
    split("");
  };
  return (
    <div className={"order-wrap" + basket.s_idx} key={basket.s_idx + pIndex}>
      <div>
        {mbAddr ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              padding: "1em",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.5",
              fontSize: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5em" }}
              >
                <PinDropIcon />
                <span style={{ paddingTop: "0.5em" }}>
                  {mbAddr[5] ? mbAddr[5] : "배달주소"}
                </span>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  background: "#BDBDBD",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  color: "#fff",
                  padding: "0.5em 0.5em 0.3em",
                }}
                onClick={() => {
                  setModalOpen(true);
                  //navigate("changeLocation");
                }}
              >
                배송지변경
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "0.2em" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <div style={{ width: "4em" }}>
                  {mbAddr[2] ? mbAddr[2] : userInfo.mb_name}
                </div>
                <div>{mbAddr[4] ? mbAddr[4] : userInfo.mb_phone}</div>
              </div>
              <div>
                {mbAddr[0]}
                {mbAddr[1] ? `/ ${mbAddr[1]}` : ""}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <div style={{ width: "4em" }}>요청사항</div>
                <input
                  className="rider-msg"
                  style={{
                    width: 0,
                    flexGrow: 1,
                    borderRadius: "8px",
                    border: "1px solid #E0E0E0",
                    background: "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                    padding: "0.5em",
                  }}
                  maxLength={100}
                  onInput={(e) => {
                    const target = e.currentTarget;
                    target.value = target.value.slice(0, 100);
                  }}
                  placeholder="배송시 요청사항 (필수입력아님)"
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              padding: "0.6em 12% 0.4em",
            }}
            onClick={() => {
              navigate("changeLocation");
            }}
          >
            <LocalShippingIcon />
            <span>배송지 추가하기</span>
            <span>+</span>
          </div>
        )}
      </div>

      <div style={{ display: "none" }}>
        <PaymentHeader
          payIndex={pIndex}
          userInfo={userInfo}
          basket={basket}
          basketDeleteHandler={basketDeleteHandler}
          pointChangeHandler={pointChangeHandler}
          nameChangeHandler={nameChangeHandler}
          telCheckHandler={telCheckHandler}
          addrCheckHandler={addrCheckHandler}
          directCheckHandler={directCheckHandler}
        />
      </div>

      {/* {basket.od_option == 6 || basket.od_option == 7 ? (
        ""
      ) : (
        <>
          <OrderRequest payIndex={pIndex} od_option={basket.od_option} />
        </>
      )} */}
      <OrderContents
        totalAmt={totalAmt}
        payIndex={pIndex}
        basket={basket}
        basketDeleteHandler={basketDeleteHandler}
        priceChangeHandler={priceChangeHandler}
        pointHandler={childComponentRef.current}
      />
      {/* <OrderRequest payIndex={pIndex} od_option={basket.od_option} />
      <OrderContents
        payIndex={pIndex}
        basket={basket}
        basketDeleteHandler={basketDeleteHandler}
        pointHandler={childComponentRef.current}
      /> */}
      {/* {basket.od_option != 7 ? ( // 월드컵 이벤트시 기프티콘 포인트 결제 막기
        <>
          <OrderUsePoint
            sIdx={basket.s_idx}
            c2Idx={basket.c2_idx}
            payIndex={pIndex}
            shopTotalAmt={basket.totalPrice + delFeeList[pIndex]}
            myPoint={myPoint}
            usePoint={usePoint}
            pointChangeHandler={pointChangeHandler}
            ref={childComponentRef}
          />
        </>) : ("")} */}

      {/* <OrderUsePoint
        sIdx={basket.s_idx}
        c2Idx={basket.c2_idx}
        payIndex={pIndex}
        shopTotalAmt={basket.totalPrice + delFeeList[pIndex]}
        myPoint={myPoint}
        usePoint={usePoint}
        pointChangeHandler={pointChangeHandler}
        ref={childComponentRef}
        userInfo={userInfo}
      /> */}
    </div>
  );
};

const DelHistoryModal = ({ userInfo, setModalOpen, setMbAddr }) => {
  const [delHistoryList, setDelHistoryList] = useState([]);
  useEffect(() => {
    if (!userInfo || !userInfo.mb_id) return;

    const formData = new FormData();
    formData.append("action", "getDelHistoryList");
    formData.append("mb_id", userInfo.mb_id);

    //{ action: "getDelHistoryList", mb_id: userInfo.mb_id }
    getDelHistoryList(formData)
      .then((res) => {
        //console.log(res);
        setDelHistoryList(res.rows);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userInfo]);

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        width: "100dvw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setModalOpen(false);
      }}
    >
      <div
        style={{
          width: "80%",
          height: "80%",
          background: "#fff",
          padding: "1em",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {userInfo.mb_addr && (
          <DelHistoryItem
            nick={"집"}
            name={userInfo.mb_name}
            phone={userInfo.mb_phone}
            mb_id={userInfo.mb_id}
            addr={userInfo.mb_addr}
            setMbAddr={setMbAddr}
            setModalOpen={setModalOpen}
          />
        )}

        {delHistoryList && delHistoryList.length ? (
          delHistoryList.map((del) => {
            return (
              <DelHistoryItem
                nick={del.nick}
                name={del.name}
                phone={del.phone}
                mb_id={del.mb_id}
                addr={del.ah_content}
                setMbAddr={setMbAddr}
                setModalOpen={setModalOpen}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const DelHistoryItem = ({
  nick,
  name,
  phone,
  mb_id,
  addr,
  setMbAddr,
  setModalOpen,
}) => {
  return (
    <div
      onClick={(e) => {
        var addr_split = addr.split("/");
        var output = [];
        output.push(addr_split[0]?.trim() || "");
        output.push(addr_split[1]?.trim() || "");
        output.push(name || "");
        output.push(mb_id || "");
        output.push(phone || "");
        output.push(nick || "");
        setMbAddr(output);
        setModalOpen(false);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          border: "1px solid #E0E0E0",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          padding: "1em",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "1.5",
          fontSize: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <PinDropIcon />
            <span style={{ paddingTop: "0.5em" }}>{nick}</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2em",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            <div style={{ width: "4em" }}>{name}</div>
            <div>{phone}</div>
          </div>
          <div>{addr}</div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
